import React, { useState, useEffect } from 'react';

// CSS
import '../Styles/home.css';
import '../Styles/styles.css';
import 'bootstrap/dist/css/bootstrap.css';

// Components
import { Routes,Route } from 'react-router-dom';
import Livechat from '../Components/livechat/livechat';
import { toast, ToastContainer } from 'react-toastify';

// Views
import Admin from '../Views/Admin'
import Login from '../Views/Login'
import Dashboard from '../Views/Dashboard'

const Home = () => {
    const [showChatUs, setShowChatUs] = useState(false);

    useEffect(() => {
        const link = document.createElement('link');
        link.href = 'https://fonts.googleapis.com/icon?family=Material+Symbols+Sharp';
        link.rel = 'stylesheet';
        document.head.appendChild(link);

        return () => {
            document.head.removeChild(link);
        };
    }, []);

    const handleClick = () => {
         setShowChatUs(!showChatUs);
     }
     const handleClose = () => {
         setShowChatUs(false);
     }
    return (
        <div className='Home'>
            <main>
                <Routes>
                    <Route path='/' element={""}/>
                    <Route path='/admin' element = {<Admin />} />
                    <Route path='/login' element = {<Login />} />
                    <Route path='/dashboard' element = {<Dashboard />} />
                </Routes>
                
        </main>
        {showChatUs &&  <Livechat  onClose={handleClose} currentUser={JSON.parse(localStorage.getItem('user')) || {data: "hola" , user : "199824556"}}/>}
        <div className="chatUs" onClick={handleClick}>
            <span className="material-symbols-sharp">chat</span>
        </div>
        <footer>
                <p>Copyright 2022 - Training App</p>
        </footer>
            <ToastContainer position='top-center' />
        </div>
        
    );
};

export default Home;