import React, { use, useEffect } from "react";
import { useAuth } from "../Auth/AuthContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const Dashboard = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    useEffect(() => {
        const response = async () => {
            const res = await fetch("http://localhost:3001/user", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include"
            });
            const data = await res.json();
            if (data.ok) {
                toast.error("Error fetching user data, login again!");
                navigate("/login");
            }


        }
        response();
    }, [user])



    return (
        <div className="dashboard">
            <header>
                <h1>Dashboard</h1>
            </header>
            <main>
                <p>Welcome, back{user.name}!</p>
                <p>Your email is: {user.email}</p>
                <p>Your role is: {user.role}</p>
            </main>
        </div>
    );
};
export default Dashboard;