import React from "react";
import "../Styles/admin.css";


const Admin = () => {

    return (<div className="admin">
            <header><h1>Admin Page</h1></header>
            <main>
       
            </main>
        </div>
    )
}
export default Admin;