import React, { useState, useEffect, useRef } from "react";
import './livechat.css';

// Functions helpers
import { randomIdGen } from "../../functions/randomGen";
import { useAuth } from "../../Auth/AuthContext";

const Livechat = ({onClose, currentUser}) => {
    const [message, setMessage] = useState("");
    const [messages, setMessages] = useState([]);
    const { user } = useAuth();
    //const user = JSON.parse(localStorage.getItem('user')) || {_id: randomIdGen(new Date().getTime()) , name : "Anonymous" , email : "anonymous@email.com", role: "Guest"};

    useEffect(() => {
        const response = async () => {
            const response = await fetch("http://localhost:3001/chat?id=" + currentUser._id, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include"
            });
            const data = await response.json();
            console.log(data);
            setMessages(data);
        }
        },[messages])

    const sendMessage = async () => {

        const response = await fetch("http://localhost:3001/chat", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                id: currentUser._id || "Anonymous",
                message,
                time: new Date().getTime(),
                type: "message"
            }),
            credentials: "include"
        });
        setMessage("");
        const data = await response.json();
        console.log(data);
    }





    return (
        <div className="chatus">
            <div className="chatus-header">
                <div className="">
                    <h2>Chatus</h2>
                </div>
                <div className="close" onClick={() => onClose()}>
                    <span className="material-symbols-sharp" >close</span>
                </div>
            </div>
            <div className="chatus-body">
                
               <div className="chatus-window">
                   {messages.map((msg, index) => (
                       <div key={index} className={`chatus-message ${
                           msg.userId === "Administrator" ? "chatus-message-admin" : "chatus-message-client"
                       }`}>
                           <p><strong>User ID:</strong> {msg.userId || "Unknown"} {msg.userId === user._id ? "(You)" : ""}</p>
                           <p><strong>Message:</strong> {msg.message}</p>
                           <p><small><strong>Time:</strong> {new Date(msg.id).toLocaleString()}</small></p>
                       </div>
                   ))}
               </div>
            </div>
            <div className="chatus-footer">
                <input
                    type="textfield"
                    value={message}
                    onChange={(e) => {
                        setMessage(e.target.value)
                    }}
                />
                <button onClick={sendMessage}><span className="material-symbols-sharp">send</span></button>
            </div>
        </div>
    );
}
export default Livechat;