import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Form } from "react-bootstrap";
import "../Styles/login.css";
import { toast } from "react-toastify";

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = async () => {
        console.log("Email:", email);
        console.log("Password:", password);
        const response = await fetch("http://localhost:3001/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email || "steamgz9@gmail.com",
                password: password || "1998",
            }),
            credentials: "include"
        });
        const data = await response.json();
        console.log(data);
        if (!data.ok) {
            console.log("Login successful!");
            localStorage.setItem("user", JSON.stringify(data.data));
            toast.success("Login successful!");
            navigate("/dashboard");
        } else {
            console.log("Invalid credentials!");
            toast.error("Invalid credentials!");
        }
    };

    return (
        <div className="login">
            <Container>
                <header>
                    <h1>Login</h1>
                </header>
                <main>
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit" onClick={handleLogin}>
                            Login
                        </Button>
                    </Form>
                </main>
            </Container>
            <button onClick={() => {
                setEmail("steamgz9@gmail.com");
                setPassword("1998");
                handleLogin();
                }}>Automatically login</button>
        </div>
    );
};

export default Login;

/*<form>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <button onClick={handleLogin}>Login</button>
                </form>*/